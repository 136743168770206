<template>
  <div class="table">
    <div class="head">
      <!-- <el-input
        placeholder="请输入内容"
        v-model="input"
        clearable
        style="width: 200px"
      >
      </el-input> -->
      <!-- <el-input
        placeholder="请输入内容"
        v-model="input"
        class="input-with-select"
        style="width: 250px"
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input> -->
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        style="width: 100%"
        border
      >
        <el-table-column prop="dictName" label="字典类型名称">
        </el-table-column>
        <el-table-column prop="dictType" label="字典类型"> </el-table-column>
        <el-table-column prop="remark" label="描述"> </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="10"
        layout="sizes, prev, pager, next"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="字典类型名称">
          <el-input v-model="formLabelAlign.dictName"></el-input>
        </el-form-item>
        <el-form-item label="字典类型">
          <el-input v-model="formLabelAlign.dictType"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="formLabelAlign.remark"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="config()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建弹窗 -->
    <el-dialog
      title="新建"
      :visible.sync="dialogVisible2"
      width="50%"
      :show-close="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="字典类型名称">
          <el-input v-model="formLabelAlign.dictName"></el-input>
        </el-form-item>
        <el-form-item label="字典类型">
          <el-input v-model="formLabelAlign.dictType"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="formLabelAlign.remark"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="config2()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysDictType,
  PostsaveType,
  PutsysupdateType,
  DeletesysDictType,
} from "@/request/api";
export default {
  name: "HtglTable2",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //编辑弹出框判定
      dialogVisible2: false, //新建弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        dictName: "",
        dictType: "",
        remark: "",
      },
      currentPage: 1, //分页
      pageSize: 10,
      id: "",
    };
  },

  methods: {
    // 编辑
    handleUpdate(v) {
      this.id = v.id;
      this.formLabelAlign.dictName = v.dictName;
      this.formLabelAlign.dictType = v.dictType;
      this.formLabelAlign.remark = v.remark;
      this.dialogVisible = true;
    },
    // 编辑确定
    config() {
      PutsysupdateType({
        createTime: "",
        deleteFlag: "",
        dictName: this.formLabelAlign.dictName,
        dictType: this.formLabelAlign.dictType,
        id: this.id,
        remark: this.formLabelAlign.remark,
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.dialogVisible = false;
          this.seelist();
        }
      });
    },
    // 删除
    handleDelete(v) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletesysDictType({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //添加数据
    clickadd() {
      this.formLabelAlign.dictName = "";
      this.formLabelAlign.dictType = "";
      this.formLabelAlign.remark = "";
      this.dialogVisible2 = true;
    },
    // 添加确定
    config2() {
      PostsaveType({
        createTime: "",
        deleteFlag: "",
        dictName: this.formLabelAlign.dictName,
        dictType: this.formLabelAlign.dictType,
        id: 0,
        remark: this.formLabelAlign.remark,
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.dialogVisible2 = false;
          this.seelist();
        }
      });
    },

    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.currentPage = val;
    },
    // 标签类型列表
    seelist() {
      GetsysDictType({
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>