import { render, staticRenderFns } from "./tablenewlable2.vue?vue&type=template&id=5eb13ba2&scoped=true&"
import script from "./tablenewlable2.vue?vue&type=script&lang=js&"
export * from "./tablenewlable2.vue?vue&type=script&lang=js&"
import style0 from "./tablenewlable2.vue?vue&type=style&index=0&id=5eb13ba2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb13ba2",
  null
  
)

export default component.exports